<template>
    <div class="p-2" v-if="trainer">

        <!-- header -->
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="trainer.owner.user_picture ? trainer.owner.user_picture : default_img"
                    class="rounded-circle" style="height:80px;width:80px" />
            </div>
            <div>
                <h3 class="text-white h4 mb-0">{{trainer.owner.user_name}}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{trainer.trainer_title}}</h3>
            </div>
            <div class="ml-auto">
                <router-link :to="`/chats/${trainer.user_id}`">
                    <i class="bx bxs-message-dots" style="font-size: 28px;cursor: pointer;color: #FFAD32;"></i>
                </router-link>
            </div>
        </div>
        <!-- end header -->

        <div class="d-flex my-1">
            <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: 150px;">
            </div>
        </div>

        <h2 class="text-white font-weight-bolder mt-2 text-center">Training Menu</h2>
        <div class="d-flex mt-1 mb-2">
            <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: 150px;">
            </div>
        </div>

        <div v-for="item in trainings">
            <router-link :to="`/training/book/${item.tr_id}`" class="d-flex align-items-center px-2 pt-1" style="gap:10px 20px"> 
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0">{{item.tr_name}}</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center font-weight-bold" style="margin: .5em 0 !important;font-weight: normal;gap:5px">
                        $ {{ item.tr_price }} / Week
                    </h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center font-weight-bold" style="margin: .5em 0 !important;font-weight: normal;gap:5px">
                        Choose Schedule
                    </h3>
                </div>
                <div>
                    <h3 class="text-white h6 mb-0 text-right" style=""><i class="bx bx-chevron-right" style="font-size: 22px;" ></i></h3>
                </div>
            </router-link>
            <div class="d-flex my-1">
                <div class="w-100 m-auto" style="background-color: #FFAD32;height: 2px;max-width: ;">

                </div>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        trainer(){
            return store.state.trainer.TRAINER
        },
        trainings(){
            return this.trainer?.trainings || []
        }
    },
    methods: {

    },
    mounted(){
        store.dispatch('trainer/GetTrainer', this.id)
    },
    data(){
        return {
            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>